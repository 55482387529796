import React, { useState, useEffect } from 'react';
import moment from 'moment';

import '../assets/components/Clock/_clock.css';

const Clock = () => {
  const [time, setTime] = useState('');
  const [dateTime, setDateTime] = useState('');

  useEffect(() => {
    const updateClock = () => {
      const now = moment();

      // Format time as 12-hour with AM/PM
      const formattedTime = now.format('h:mm A');

      // Format date as day name, month name, date, year
      const formattedDate = now.format('dddd, MMM D, YYYY');

      setTime(formattedTime);
      setDateTime(formattedDate);
    };

    updateClock();
    const intervalId = setInterval(updateClock, 1000);

    // Cleanup the interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div id="clock">
      <div className="time">{time}</div>
      <div className="date-time">{dateTime}</div>
    </div>
  );
};

export default Clock;
