import React, { createContext, useEffect, useState, useContext, useRef } from 'react';
import moment from 'moment-timezone';
import supabase from '../lib/supabase';
import { LocationContext } from './LocationContext';
import { AudioContext } from './AudioContext';

// Create contexts
const OrdersContext = createContext();
const UpdateOrdersContext = createContext();

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// Orders provider
const OrdersProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);
  const [openOrders, setOpenOrders] = useState([]);
  const [futureOrders, setFutureOrders] = useState([]);
  const [preparedOrders, setPreparedOrders] = useState([]);
  const [totalOrderCount, setTotalOrderCount] = useState(0);
  const [totalOrderLineItems, setTotalOrderLineItems] = useState(0);
  const [totalFutureOrderCount, setTotalFutureOrderCount] = useState(0);
  const [totaFuturelOrderLineItems, setTotalFutureOrderLineItems] = useState(0);

  const { activeLocation } = useContext(LocationContext);
  const { playSound } = useContext(AudioContext);
  const prevOrderCountRef = useRef();
  const ordersSubscriptionRef = useRef(null); // Store the subscription reference

  // Effect to play sound when totalOrderCount increases
  useEffect(() => {
    //console.log("totalOrderCount changed! Did it go up?", prevOrderCountRef.current, totalOrderCount);
    if (!isNaN(prevOrderCountRef?.current) && totalOrderCount > prevOrderCountRef.current) {
      //console.log("Yes, it went up! Playing a sound");
      playSound();  // Play sound only if totalOrderCount has increased
    }
    prevOrderCountRef.current = totalOrderCount;
  }, [totalOrderCount]);

  // Refs to hold the current state
  const futureOrdersRef = useRef(futureOrders);
  const openOrdersRef = useRef(openOrders);
  const preparedOrdersRef = useRef(preparedOrders);

  // Function to fetch initial data from the view
  const fetchOrders = async () => {
    //console.log("Fetching orders");

    const { data, error } = await supabase
      .from('kds_view')
      .select('*')
      .eq('location_id', activeLocation.id);
    //console.log(data);

    if (error) {
      console.error('Error fetching orders:', error);
    } else {
      //Order the modifiers by ordinal
      //console.log(data);
      if (!error && data) {
        data.forEach(order => {
          //console.log(order);

          //Sort the order line items
          order.order_line_items.sort((a,b) => { 
            if (a.id < b.id) {
              return -1;
            }
            if (a.id > b.id) {
              return 1;
            }
            return 0; // if a.id is equal to b.id
          });

          //Sort the order line item modifiers
          order.order_line_items.forEach(line_item => {
            if (Array.isArray(line_item?.order_line_item_modifiers)) {
              line_item.order_line_item_modifiers.sort((a, b) => {
                // Directly access the ordinal of the list object
                let ordinalA = a.modifier?.list?.ordinal || Infinity; // Use Infinity as a fallback for undefined ordinals
                let ordinalB = b.modifier?.list?.ordinal || Infinity;
              
                return ordinalA - ordinalB;
              });                         
            };
          });
        });
        
        //console.log("Sorted data:", data);
      }

      const adjustedOrders = data.map((order) => {
        if (order.pickuptime) {
          order.pickuptime = moment.utc(order.pickuptime)
            .tz(userTimeZone)
            .format('YYYY-MM-DD HH:mm:ss');
        }
        //console.log(order.pickuptime);
        if (order.created_at) {
          order.created_at = moment.utc(order.created_at)
            .tz(userTimeZone)
            .format('YYYY-MM-DD HH:mm:ss');
        }
        if (order.prepared_at) {
          order.prepared_at += 'Z'; //Have to add a Z because this comes in as UTC but isn't marked in a way JS understands properly, causing issues when comparing to Date.now()
        }

        order.created_display_name = false;
        if (!order?.display_name && Array.isArray(order.order_line_items)) {
          let firstLineItem = order.order_line_items.find(line_item => line_item?.variation?.item?.name);
          if (!firstLineItem) firstLineItem = order.order_line_items.find(line_item => line_item?.name);

          if (firstLineItem) {
            order.created_display_name = true;
            if (Array.isArray(firstLineItem?.order_line_item_modifiers)) {
              firstLineItem?.order_line_item_modifiers.forEach((modifier) => {
                if (modifier.is_prefix === 1) {
                  //console.log("It's included!", modifier);
                  firstLineItem.variation.item.name = modifier.name + ' ' + firstLineItem.variation.item.name;
                }
              })
            }
            //console.log(firstLineItem);
            order.display_name = firstLineItem?.variation?.item?.name || firstLineItem?.name;

            if (order.order_line_items.length > 1) {
              order.display_name += ' +';
            }
          }
        }
        //console.log(order.created_display_name, order.display_name, order);

		    // Loop through line items and compute additional time
        if (Array.isArray(order.order_line_items)) {
          let order_prep_time = Math.ceil(order.order_line_items.length / activeLocation.kds_items_per) * activeLocation.kds_minutes_per;
          const isDelivery = (order?.source === 'DOORDASH' || order?.type === "DELIVERY") ?? false;
                                        
          /*
          order.order_line_items.forEach((lineItem, index) => {
            // Logic for determining state and additional prep time here, similar to your original code
          });
          */
          
          if (isDelivery) {
            order_prep_time += activeLocation.kds_delivery_extra_minutes; // Calculate total preparation time
          }

          // Set order starttime based on preparation time and pickup time
          let pickuptime_date = moment.utc(order.pickuptime);
          order.starttime = pickuptime_date.subtract(order_prep_time, 'minutes').format('YYYY-MM-DD HH:mm');
        }

        return order;
      });

      if (isNaN(prevOrderCountRef?.current)) {
        prevOrderCountRef.current = adjustedOrders.filter((item) => item.kds_status === 'PROPOSED' && new Date(item.starttime) <= (new Date())).length;  // Store current count in ref on component mount and after updates
      }
  	  setOrders(adjustedOrders);
    }
  };

  const updateStateIfChanged = (newState, setState, ref, onChange) => {
    if (JSON.stringify(ref.current) !== JSON.stringify(newState)) {
      setState(newState);
      ref.current = newState; // Keep ref updated
      if (onChange) onChange(newState); // Call onChange if provided
    }
  };
  
  const parseDateSafely = (date) => {
    const parsedDate = new Date(date);
    return isNaN(parsedDate.getTime()) ? new Date(date.replace(/-/g, '/')) : parsedDate;
  };
  
  const classifyOrders = (orders) => {
    const now = new Date();
  
    const proposedOrders = orders.filter(
      (item) => item.kds_status === 'PROPOSED' && parseDateSafely(item.starttime) <= now
    );
    const preparedOrders = orders.filter((item) => item.kds_status === 'PREPARED');
    const futureOrders = orders.filter(
      (item) => item.kds_status === 'PROPOSED' && parseDateSafely(item.starttime) > now
    );
  
    return { proposedOrders, preparedOrders, futureOrders };
  };
  
  const calculateTotals = (orders) => ({
    count: orders.length,
    lineItems: orders.reduce(
      (total, order) => total + (Array.isArray(order.order_line_items) ? order.order_line_items.length : 0),
      0
    ),
  });
  
  useEffect(() => {
    const { proposedOrders, preparedOrders, futureOrders } = classifyOrders(orders);
  
    updateStateIfChanged(proposedOrders, setOpenOrders, openOrdersRef, (newOrders) => {
      const { count, lineItems } = calculateTotals(newOrders);
      setTotalOrderCount(count);
      setTotalOrderLineItems(lineItems);
    });
  
    updateStateIfChanged(preparedOrders, setPreparedOrders, preparedOrdersRef);
  
    updateStateIfChanged(futureOrders, setFutureOrders, futureOrdersRef, (newOrders) => {
      const { count, lineItems } = calculateTotals(newOrders);
      setTotalFutureOrderCount(count);
      setTotalFutureOrderLineItems(lineItems);
    });
  }, [orders]);
  
  
  useEffect(() => {
    futureOrdersRef.current = futureOrders;
    openOrdersRef.current = openOrders;
  }, [futureOrders, openOrders]);
  
  useEffect(() => {
    const updateOrderClassification = () => {
      const now = new Date();
    
      const newFutureOrders = futureOrdersRef.current.filter(
        (order) => new Date(order.starttime) > now
      );
      const newOpenOrders = [
        ...openOrdersRef.current,
        ...futureOrdersRef.current.filter((order) => new Date(order.starttime) <= now),
      ];
    
      updateStateIfChanged(newFutureOrders, setFutureOrders, futureOrdersRef, (newOrders) => {
        const { count, lineItems } = calculateTotals(newOrders);
        setTotalFutureOrderCount(count);
        setTotalFutureOrderLineItems(lineItems);
      });
    
      updateStateIfChanged(newOpenOrders, setOpenOrders, openOrdersRef, (newOrders) => {
        const { count, lineItems } = calculateTotals(newOrders);
        setTotalOrderCount(count);
        setTotalOrderLineItems(lineItems);
      });
    };    
  
    const intervalId = setInterval(updateOrderClassification, 5000); // Check every 5 seconds
    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);
  
  const subscribeToOrders = () => {
      //console.log("Subscribing to orders");
      
      // Check if a subscription already exists
      if (ordersSubscriptionRef.current) {
        //console.log("Order subscription already exists. Skipping...");
        return; // Prevent multiple subscriptions
      }
      
      const ordersSubscription = supabase
        .channel('orders-changes')
        .on(
          'postgres_changes',
          { event: '*', schema: 'public', table: 'orders', filter: `location_id=eq.${activeLocation.id}` },
          (payload) => {
            const updatedOrder = payload.new;
            //console.log("Updated Order",updatedOrder);
            if (payload.eventType === 'INSERT') {
              //console.log("Inserted order forcing fetchOrders");
              setTimeout(() => {
                fetchOrders();
              },1000); //Put this in a timeout to give the edge function a second longer to finish updating the line items & modifiers - sometimes this triggers too quickly and only displays the order (or nothing at all, if there are no line items)
            } else if (payload.eventType === 'UPDATE') {
              //console.log("Update in orders sub calling setOrders");
              setOrders((prevOrders) => {
                const existingOrder = prevOrders.find((order) => order.id === updatedOrder.id);
                if (existingOrder) {
                  if (existingOrder?.pickuptime !== moment.utc(updatedOrder?.pickuptime).tz(userTimeZone).format('YYYY-MM-DD HH:mm:ss')) { //Have to convert the pickuptime from the DB to match the converted .pickuptime on our end
                    //console.log("Order Update forcing fetchOrders",updatedOrder);
                    //console.log(existingOrder?.pickuptime, "!==", moment.utc(updatedOrder?.pickuptime).tz(userTimeZone).format('YYYY-MM-DD HH:mm:ss').replace(' ','T'));
                    fetchOrders();
                    return prevOrders;
                  } else {
                    //console.log("Updating data, not refetching");
                    return prevOrders.map((order) =>
                      order.id === updatedOrder.id
                        ? {
                            ...order,
                            ...updatedOrder,
                            display_name: updatedOrder?.display_name === null ? order.display_name : updatedOrder.display_name,
                            pickuptime: order?.pickuptime || updatedOrder?.pickuptime
                          }
                        : order
                    );
                  }
                } else {
                  //console.log("Not in existingOrders, we're out of sync for some reason. Refresh");
                  fetchOrders();
                  return prevOrders;
                }
              });
            }
        }
      )
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'order_line_items' },
        (payload) => {
          const updatedLineItem = payload.new;
          if (payload.eventType === 'UPDATE') {
            //console.log("Update order_line_items sub forcing setOrders",updatedLineItem);
            setOrders((prevOrders) =>
              prevOrders.map((order) => ({
                ...order,
                order_line_items: order.order_line_items.map((lineItem) =>
                  lineItem.id === updatedLineItem.id ? { ...lineItem, state: updatedLineItem.state } : lineItem
                ),
              }))
            );
          }
        }
      )
      .subscribe((status) => {
        //console.log("Subscription status",status);
        if (status === 'SUBSCRIBED') {
          //console.log('Subscribed to orders-changes channel')
          ordersSubscriptionRef.current = ordersSubscription;
        } else if (status === 'SUBSCRIPTION_ERROR' || status === 'TIMED_OUT' || status === 'CHANNEL_ERROR') {
          //console.log("cleanup in .on(status)")
          if (ordersSubscriptionRef.current) {
            supabase.removeChannel(ordersSubscriptionRef.current); // Clean up on error
            ordersSubscriptionRef.current = null; // Reset the ref
          }

          setTimeout(() => {
            fetchOrders();
            subscribeToOrders(); // Retry subscription on error - DO NOT ADD AWAIT KEYWORD HERE! IT CAN CAUSE AN INFINITE LOOP DUE TO RECURSIVELY CREATING NEW CONNECTIONS WHEN RECEIVING CHANNEL_ERRORs
          },1000); //Wait 1 second between retries to give the error time to clear
          //reject(new Error('Subscription failed or timed out'));
        } else { //Likely status === 'CLOSED'
          ordersSubscriptionRef.current = null; // Reset the ref
        }
      })
  };

  useEffect(() => {
    if (!activeLocation || activeLocation?.subscription_level < 3) 
      return;

    const setupSubscriptions = async () => {
        //console.log("activeLocation changed, subscribing", activeLocation);
        try {
          fetchOrders();
          subscribeToOrders();
        } catch (error) {
            console.error("Error updating order queue:", error);
        }
    };

    setupSubscriptions();

    const handleVisibilityChange = async () => {
        if (document.visibilityState === 'visible') {
          setupSubscriptions();
        } else {
          //console.log("The page is now hidden from the user.");
          if (ordersSubscriptionRef.current) {
            supabase.removeChannel(ordersSubscriptionRef.current); // Clean up on error
            ordersSubscriptionRef.current = null; // Reset the ref
          }
        }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return async () => {
        //console.log("Cleanup on unmount or dependency change");
        if (ordersSubscriptionRef.current) {
          supabase.removeChannel(ordersSubscriptionRef.current); // Remove the relevant channel
          ordersSubscriptionRef.current = null; // Reset the ref
        }
        document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [activeLocation]);

  return (
    <OrdersContext.Provider value={{ orders, openOrders, preparedOrders, futureOrders, totalOrderCount, totalOrderLineItems, totalFutureOrderCount, totaFuturelOrderLineItems }}>
      <UpdateOrdersContext.Provider value={{ setOrders }}>
        {children}
      </UpdateOrdersContext.Provider>
    </OrdersContext.Provider>
  );
};

export { OrdersContext, OrdersProvider };