import React, { useContext } from 'react';
import { AudioContext } from '../context/AudioContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh, faVolumeXmark } from '@fortawesome/free-solid-svg-icons';

const AudioButton = () => {
  const { toggleAudio, isAudioEnabled } = useContext(AudioContext);

  return (
    <div onClick={toggleAudio} className="header-count audio-button">
      {isAudioEnabled ? <FontAwesomeIcon icon={faVolumeHigh}/> : <FontAwesomeIcon icon={faVolumeXmark}/>}
    </div>
  );
};

export default AudioButton;