import React, { createContext, useState, useEffect } from 'react';
import supabase from '../lib/supabase';

const LocationContext = createContext();
const UpdateLocationContext = createContext();

const areObjectsEqual = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

const LocationProvider = ({ children }) => {
  const [activeLocation, setActiveLocation] = useState(() => {
    const savedLocationId = localStorage.getItem('activeLocation');
    return savedLocationId ? JSON.parse(savedLocationId) : {id:0,name:''};
  });
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      const { data, error } = await supabase
        .from('locations')
        .select('*')
        .eq('archived', 0)
        .order('ordinal', { ascending: true });

      if (!error) {
        setLocations(data);
      }
    };
    fetchLocations();
  }, []);

  useEffect(() => {
    if (locations.length > 0) {
      const foundItem = locations.find(item => item.id === activeLocation.id);
      if (!foundItem) {
        setActiveLocation(locations[0]);
      } else if (!areObjectsEqual(activeLocation, foundItem)) {
        setActiveLocation(foundItem);
      }
    }
  }, [locations, activeLocation.id]);

  useEffect(() => {
    if (activeLocation !== null) {
      localStorage.setItem('activeLocation', JSON.stringify(activeLocation));
    } else {
      localStorage.removeItem('activeLocation');
    }
  }, [activeLocation]);

  const updateLocationData = async (locationId, fieldName, newValue) => {
    const { error } = await supabase
      .from('locations')
      .update({ [fieldName]: newValue })
      .eq('id', locationId);

    if (error) {
      console.error("Failed to update location in database:", error);
      return false;
    }

    setLocations(prevLocations => prevLocations.map(location => {
      if (location.id === locationId) {
        return { ...location, [fieldName]: newValue };
      }
      return location;
    }));

    if (activeLocation.id === locationId) {
      setActiveLocation(prevActiveLocation => {
        const updatedLocation = { ...prevActiveLocation, [fieldName]: newValue };
        return areObjectsEqual(prevActiveLocation, updatedLocation) ? prevActiveLocation : updatedLocation;
      });
    }

    return true;
  };

  return (
    <LocationContext.Provider value={{ activeLocation, locations }}>
      <UpdateLocationContext.Provider value={{setActiveLocation, setLocations, updateLocationData}}>
        {children}
      </UpdateLocationContext.Provider>
    </LocationContext.Provider>
  );
};

export { LocationContext, UpdateLocationContext, LocationProvider };
