import React, { createContext, useState, useEffect } from 'react';

// Create the audio context
const WindowAudioContext = window.AudioContext || window.webkitAudioContext;
const windowAudioContext = new WindowAudioContext();

// Create a React context for the audio
const AudioContext = createContext();

const AudioProvider = ({ children }) => {
  const [isAudioEnabled, setAudioEnabled] = useState(false);
  const [audioBuffer, setAudioBuffer] = useState(null);

  // Function to load and decode audio data
  const loadAudio = (url) => {
    return fetch(url)
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => windowAudioContext.decodeAudioData(arrayBuffer))
      .then(decodedData => {
        setAudioBuffer(decodedData); // Store the decoded audio data
      })
      .catch(error => console.error('Error loading audio:', error));
  };

  // Load the audio file on component mount
  useEffect(() => {
    loadAudio('/assets/audio/da-ding.mp3');
  }, []);

  const playSound = () => {
    //console.log("Should I play a sound?", isAudioEnabled, audioBuffer);
    if (isAudioEnabled && audioBuffer) {
      const source = windowAudioContext.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(windowAudioContext.destination);
      source.start(0); // Start playback immediately
    }
  };

  const toggleAudio = () => {
    setAudioEnabled(!isAudioEnabled);
  };

  useEffect(() => {
    if (isAudioEnabled) {
      //console.log("Audio enabled now, resuming audio context...");
      windowAudioContext.resume().then(() => {
        //console.log("Audio context resumed.");
        playSound();
      });
    }
  }, [isAudioEnabled]);

  return (
    <AudioContext.Provider value={{ playSound, toggleAudio, isAudioEnabled }}>
      {children}
    </AudioContext.Provider>
  );
};

export { AudioContext, AudioProvider };
