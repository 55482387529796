import React, { Fragment, useContext } from "react";
import cx from "classnames";
import { Nav } from 'reactstrap';
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { LocationContext } from "../../context/LocationContext";
import HeaderLogo from "../AppLogo";
import UserBox from "./Components/UserBox";
import HeaderDots from "./Components/HeaderDots";
import OrderCount from "./Components/OrderCount";
import AudioButton from "../../components/AudioButton";

const Header = () => {
  const { activeLocation } = useContext(LocationContext);
//console.log(activeLocation);
  return (
    <Fragment>
      <TransitionGroup>
        <CSSTransition
          component="div"
          id="app-header"
          className="app-header header-shadow"
          appear={true} timeout={1500} enter={false} exit={false}>
          <div>
            <HeaderLogo />
            <div className="app-header__content">
              <div className="app-header-left">
                <Nav className="header-megamenu">
                  <HeaderDots/>
                </Nav>
              </div>
              <div className="app-header-right">
                {activeLocation.subscription_level >= 3 && (
                  <>
                    <OrderCount/>
                    <AudioButton/>
                  </>
                )}
                <UserBox />
              </div>
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </Fragment>
  );
};

export default Header;
