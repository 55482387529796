import React, { createContext, useEffect, useState, useContext, useRef } from 'react';
import supabase from '../lib/supabase';
import { LocationContext } from './LocationContext';

// Create contexts
const ExpoBoxContext = createContext();
const UpdateExpoBoxContext = createContext();

// ExpoBox provider
const ExpoBoxProvider = ({ children }) => {
  const [expoBoxes, setExpoBoxes] = useState([]);
  const { activeLocation } = useContext(LocationContext);

  const expoBoxSubscriptionRef = useRef(null); // Store the subscription reference

  // Function to fetch initial data from the expo_boxes table
  const fetchExpoBoxes = async () => {
    const { data, error } = await supabase
      .from('expo_boxes')
      .select('*')
      .eq('location_id', activeLocation.id); // Adjust based on your location structure
    //console.log("Boxes:",data);
    if (error) {
      console.error('Error fetching expo boxes:', error);
    } else {
      setExpoBoxes(data);
    }
  };

  useEffect(() => {
    // Start the subscription if there are expo boxes
    if ((expoBoxes?.length || 0) > 0) {
      subscribeToExpoBoxes();
    };
  
  }, [expoBoxes]);

  useEffect(() => {
    if (activeLocation?.id) {
      //console.log("Fetching Expo Boxes for",activeLocation);
      fetchExpoBoxes();
      document.addEventListener("visibilitychange", handleVisibilityChange);

      // Cleanup subscription on unmount
      return () => {
        //console.log("Cleanup on unmount Expo Boxes on unmount or visibility change");
        if (expoBoxSubscriptionRef.current) {
          supabase.removeChannel(expoBoxSubscriptionRef.current); // Clean up on error
          expoBoxSubscriptionRef.current = null; // Reset the ref
        }
        document.removeEventListener("visibilitychange", handleVisibilityChange);
      };
    }
  }, [activeLocation]);
    
  const subscribeToExpoBoxes = () => {
    //console.log("Subscribing to Expo Boxes");

    // Check if a subscription already exists
    if (expoBoxSubscriptionRef.current) {
      console.log("Expo subscription already exists. Skipping...");
      return; // Prevent multiple subscriptions
    }

    // Subscribe to real-time updates for the 'expo_boxes' table
    const expoBoxSubscription = supabase
      .channel('expo-boxes-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'expo_boxes',
          filter: `location_id=eq.${activeLocation.id}`, // Filter on location_id
        },
        (payload) => {
          const updatedBox = payload.new;
          //console.log("expo_boxes updated. Payload", payload);

          if (payload.eventType === 'INSERT') {
            // Add the new box to the state
            setExpoBoxes((prevBoxes) => [...prevBoxes, updatedBox]);
          } else if (payload.eventType === 'UPDATE') {
            // Update the specific box in the state
            setExpoBoxes((prevBoxes) => {
              const existingBox = prevBoxes.find((box) => box.id === updatedBox.id);

              if (existingBox) {
                // Update the box if it already exists
                return prevBoxes.map((box) =>
                  box.id === updatedBox.id ? { ...box, ...updatedBox } : box
                );
              } else {
                // If the box doesn't exist, add it to the state
                return [...prevBoxes, updatedBox];
              }
            });
          } else if (payload.eventType === 'DELETE') {
            // Remove the deleted box from the state
            setExpoBoxes((prevBoxes) =>
              prevBoxes.filter((box) => box.id !== payload.old.id)
            );
          }
        }
      )
      .subscribe((status) => {
        //console.log("Expo Box Subscription - Status Received", status);
        if (status === "SUBSCRIBED") {
          //console.log("Subscribed");
         expoBoxSubscriptionRef.current = expoBoxSubscription;
        } else if (status === "SUBSCRIPTION_ERROR" || status === "TIMED_OUT" || status === "CHANNEL_ERROR") {
          if (expoBoxSubscriptionRef.current) {
            supabase.removeChannel(expoBoxSubscriptionRef.current); // Clean up on error
            expoBoxSubscriptionRef.current = null; // Reset the ref
          }

          setTimeout(() => {
            subscribeToExpoBoxes();
          },1000); //Wait 1 second between retries to give the error time to clear
        } else { //Likely status === 'CLOSED'
          expoBoxSubscriptionRef.current = null; // Reset the ref
        }
      });

    return expoBoxSubscription;
  };

  const handleVisibilityChange = async () => {
    if (document.visibilityState === 'visible') {
        //console.log("The page is now visible to the user.");
        await fetchExpoBoxes(); //This should change the expoBoxes var, which will fire the subscribe function again, so no need to call that function here
    } else {
        //console.log("The page is now hidden from the user.");
        if (expoBoxSubscriptionRef.current) {
          supabase.removeChannel(expoBoxSubscriptionRef.current); // Clean up on error
          expoBoxSubscriptionRef.current = null; // Reset the ref
        }
    }
  }

  return (
    <ExpoBoxContext.Provider value={{ expoBoxes }}>
      <UpdateExpoBoxContext.Provider value={{ setExpoBoxes }}>
        {children}
      </UpdateExpoBoxContext.Provider>
    </ExpoBoxContext.Provider>
  );
};

export { ExpoBoxContext, ExpoBoxProvider };
