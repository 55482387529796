import React, { useContext, Fragment, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import { LocationContext } from "../../../context/LocationContext";

const HeaderDots = () => {
  const { activeLocation } = useContext(LocationContext);
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const handleNavigation = (route) => {
    navigate(route);
    document.body.click(); // Simulate clicking outside to close any open dropdowns
  };

  return (
    <Fragment>
      <div className="header-dots" style={{ height: '60px' }}>
        {activeLocation?.subscription_level >= 3 ? (
          <>
          <Button
            className="btn-icon-vertical btn-square btn-transition"
            style={{ padding: '0 10px', minWidth: '65px' }}
            outline
            color="link"
            onClick={() => handleNavigation('/kds/')}
          >
            <i className="fa-solid fa-table-columns btn-icon-wrapper btn-icon-md"
              style={{ fontSize: '1.5rem' }}></i>
            KDS
          </Button>
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle
              caret
              outline
              color="link"
              className="btn-icon-vertical btn-square btn-transition"
              style={{ padding: '0 10px', minWidth: '65px' }}
            >
              <i className="fa-solid fa-ellipsis btn-icon-wrapper btn-icon-md"
                  style={{ fontSize: '1.5rem', marginRight: '10px' }}></i>
              More
            </DropdownToggle>
            
    <DropdownMenu className="dropdown-menu-custom">
      <Row className="row-custom">
        <Col
          md="4"
          className="col-custom"
          onClick={() => handleNavigation('/checklists/')}
        >
          <i className="fa-solid fa-square-check menu-icon btn-icon-vertical btn-icon-md"
                    style={{ fontSize: '1.5rem' }}></i>
          Checklists
        </Col>
        <Col
          md="4"
          className="col-custom col-gray-bg"
          onClick={() => handleNavigation('/prep-dashboard/')}
        >
          <i className="fa-solid fa-tachograph-digital menu-icon btn-icon-vertical btn-icon-md"
                    style={{ fontSize: '1.5rem' }}></i>
          Prep
        </Col>
        <Col
          md="4"
          className="col-custom"
          onClick={() => handleNavigation('/recipe-box/')}
        >
          <i className="fa-solid fa-box-archive menu-icon btn-icon-vertical btn-icon-md"
                    style={{ fontSize: '1.5rem' }}></i>
          Recipes
        </Col>
      </Row>

      <Row className="row-custom">
        <Col
          md="4"
          className="col-custom col-gray-bg"
          onClick={() => handleNavigation('/waste-entry/')}
        >
          <i className="fa-solid fa-trash-can menu-icon btn-icon-vertical btn-icon-md"
                    style={{ fontSize: '1.5rem' }}></i>
          Waste
        </Col>
        <Col md="4" className="col-custom">&nbsp; <i> </i></Col>
        <Col md="4" className="col-custom col-gray-bg">&nbsp;</Col>
      </Row>
    </DropdownMenu>
          </Dropdown>
          </>
        ) : (
          <>
            <Button
              className="btn-icon-vertical btn-square btn-transition"
              style={{ padding: '0 10px', minWidth: '65px' }}
              outline
              color="link"
              onClick={() => handleNavigation('/prep-dashboard/')}
            >
              <i className="fa-solid fa-tachograph-digital btn-icon-wrapper btn-icon-md"
                style={{ fontSize: '1.5rem' }}></i>
              Prep
            </Button>

            <Button
              className="btn-icon-vertical btn-square btn-transition"
              style={{ padding: '0 10px', minWidth: '65px' }}
              outline
              color="link"
              onClick={() => handleNavigation('/recipe-box/')}
            >
              <i className="fa-solid fa-box-archive btn-icon-wrapper btn-icon-md"
                style={{ fontSize: '1.5rem' }}></i>
              Recipes
            </Button>

            <Button
              className="btn-icon-vertical btn-square btn-transition"
              style={{ padding: '0 10px', minWidth: '65px' }}
              outline
              color="link"
              onClick={() => handleNavigation('/waste-entry/')}
            >
              <i className="fa-solid fa-trash-can btn-icon-wrapper btn-icon-md"
                style={{ fontSize: '1.5rem' }}></i>
              Waste
            </Button>
          </>
        )}
      </div>
    </Fragment>
  );
};

export default HeaderDots;
