import React from 'react';
import moment from 'moment';
import '../assets/components/OrderSummary/_ordersummary.css';

const OrderSummary = ({ order, onClick }) => {
  return (
    <div className="order-summary" key={order.id} onClick={() => onClick(order)}>
      <div className="order-header">
        {order?.display_name}
      </div>
	  <div className="pickup-time">Pickup Time:<br/>{order?.pickuptime ? (
		<>
		{moment(order.pickuptime).format('ddd M/D')}
		<br/>
		{moment(order.pickuptime).format('h:mm a')}
		</>
	  ) : ('')}</div>

	  {(order.type === 'DELIVERY' || order.source === 'DOORDASH') && (
        <div className="delivery-tag" key="delivery-tag">For Delivery</div>
      )}
      {order.note && (
        <div className="order-note" key="note">
          <span className="note">Note: {order.note}</span>
        </div>
      )}
      <div className="order-body" key="order-body">
        {order?.order_line_items.map((item, index) => (
			<div className="line-item" key={item.id}>
				<div className="name">
					{(Array.isArray(item?.order_line_item_modifiers)) && item.order_line_item_modifiers.map((modifier) => {
						//console.log("Before prefix includes", modifier);
						if (modifier.is_prefix === 1) {
							//console.log("It's included!", modifier);
							return (
								<span key={modifier.modifier_id}>
									{modifier.name}&nbsp;
								</span>
							);
						} else {
							return null; // If not included, return null to avoid rendering anything
						}
					})}
					{item?.variation?.item?.name} {(item?.variation?.item?.variations?.length > 1) && ` - ${item.variation.name}`}
				</div>
				{item.note && <div className="notes">Note: {item.note}</div>}
				<div className="modifiers">
				{Array.isArray(item?.order_line_item_modifiers) && item.order_line_item_modifiers.map((modifier, index) => {
					//console.log(modifier);
					if (modifier.is_prefix) {
					return null;
					} else if (modifier.highlighted && modifier.modifier_id === modifier.pre_selected) {
					return null;
					} else {
					return (
						<div key={modifier.modifier_id + '-' + index} className="modifier">
						{modifier.name}
						</div>
					)
				}
				})}
				</div>
			</div>
        ))}
      </div>
    </div>
  );
}

export default OrderSummary;
