import React, { Fragment, useContext } from "react";
import { AccountContext } from "../../../context/AccountContext";
import { LocationContext } from "../../../context/LocationContext";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'react-toastify/dist/ReactToastify.css';

import Clock from "../../../components/Clock";

const UserBox = () => {
  const { logout } = useContext(AccountContext);
  const { activeLocation } = useContext(LocationContext);
  //console.log("Account in userBox",account);
  //console.log("Location:",activeLocation);

  const handleLogout = () => {
    console.log("Logout Clicked");
    logout();
  };

  return (
    <Fragment>
      <div className="header-btn-lg pe-0" style={{ paddingLeft: '5px', marginLeft: '0' }}>
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <UncontrolledButtonDropdown>
              <DropdownToggle color="Dark" className="p-0 d-flex align-items-center" style={{minWidth: '150px'}}>
                <FontAwesomeIcon className="ms-2 opacity-8" icon={faAngleDown} style={{fontSize: '1rem'}} />
                <div className="widget-content-left header-user-info">
                  <Clock/>
                </div>
              </DropdownToggle>
              <DropdownMenu style={{width: '15rem'}}>
                  <PerfectScrollbar>
                    <Nav vertical>
                      <NavItem>
                        <h3>{activeLocation?.display_name}</h3>
                        <NavLink href="#" onClick={handleLogout}>
                          Log Out
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </PerfectScrollbar>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserBox;
