import React, { Fragment, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from "react-perfect-scrollbar";
import { OrdersContext } from '../../../context/OrdersContext';
import { LocationContext, UpdateLocationContext } from '../../../context/LocationContext';
import { Col, Row, UncontrolledButtonDropdown, DropdownMenu, DropdownToggle, Nav, NavItem, Modal, ModalBody } from 'reactstrap';
import OrderSummary from '../../../components/OrderSummary';
import { InputGroup, ModalFooter, ModalHeader } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { toast, Bounce } from "react-toastify";
import moment from 'moment';

import supabase from '../../../lib/supabase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const OrderCount = () => {
  const navigate = useNavigate();
  const { preparedOrders, futureOrders, totalOrderCount, totalOrderLineItems, totalFutureOrderCount, totaFuturelOrderLineItems } = useContext(OrdersContext);
  const [ showTellUsModal, setShowTellUsModal ] = useState(false);
  const [ showPickupTimeModal, setShowPickupTimeModal ] = useState(false);
  const [ selectedOrder, setSelectedOrder ] = useState({});
  const [ pickupTime, setPickupTime ] = useState();

  const toggleTellUsModal = () => { setShowTellUsModal(!showTellUsModal) };
  const togglePickupTimeModal = () => { setShowPickupTimeModal(!showPickupTimeModal) };

const startItNow = () => {
  const difference = new Date(selectedOrder.pickuptime).getTime() - new Date(selectedOrder.starttime).getTime();
  saveNewPickupTime(new Date(new Date().getTime() + difference));
}

  const saveNewPickupTime = async (pickup) => {
    console.log("Changing pickup time to",{pickuptime:pickup.toISOString(), id:selectedOrder.id});
    
    const { error } = await supabase
      .from('orders')
      .update({pickuptime:(moment(pickup).utc().format())})
      .eq('id',selectedOrder.id);

    if (error) {
      console.error("There was an error updating the order pickup time",error);
      this.toastId = toast(
        "There was an error updating the order pickup time",
        {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "error",
        }
      )
    }

    togglePickupTimeModal();
  }

  const handlePickupTimeChange = (date) => {
    setPickupTime(date); // Set the new date into the state
  };

  const removeFromCounter = async () => {
    //console.log("removing",selectedOrder,"from counter");
    const { error } = await supabase.from('orders').update({ kds_status:'COMPLETED', completed_at:(new Date().toISOString()) }).eq('id', selectedOrder.id);
    if (error) {
      console.error('Error updating order status:',error);
    }
    toggleTellUsModal();
  }

  const tell_us = preparedOrders.filter(item => item.box_number === 'counter');

  return (
    <Fragment>
      {tell_us.length > 0 && (
        <>
          <UncontrolledButtonDropdown>
            <DropdownToggle color="Dark" className="p-0 d-flex align-items-center no-caret" style={{marginRight: '5px', background:'#0F79ED', borderRadius:'.25rem', color:'#fff'}}>
                <div className="header-count screen-count widget-content-left header-user-info">
                  <Row>
                  <Col md="12" style={{fontSize:'.9rem'}}>
                    <b>Counter</b>
                  </Col>
                  </Row>
                  <Row>
                  <Col md="12">
                    <div style={{fontSize: '1.3rem', lineHeight:'1.3rem'}}>{tell_us.length}</div>
                    <div style={{fontSize: '.6rem'}}>Orders</div>
                  </Col>
                  </Row>
                </div>
            </DropdownToggle>
            <DropdownMenu>
                <PerfectScrollbar>
                  <Nav vertical>
                    <NavItem>
                      {tell_us.map((order) => (
                        <OrderSummary key={order.id} order={order} onClick={() => { 
                          setSelectedOrder(order);
                          toggleTellUsModal();
                        }}/>
                      ))}
                    </NavItem>
                  </Nav>
                </PerfectScrollbar>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
          <Modal id="tell-us-modal" isOpen={showTellUsModal}>
            <ModalBody>
              Do you want to mark this order as complete?
            </ModalBody>
            <ModalFooter>
              <button id="Cancel" onClick={toggleTellUsModal} className="btn btn-secondary">No</button>
              <button id="Ok" onClick={removeFromCounter} className="btn btn-primary">Yes</button>
            </ModalFooter>
          </Modal>
        </>
      )}
      <div className="header-count" onClick={() => {navigate('/kds')}}>
        <Row>
        <Col md="12" style={{fontSize:'.9rem'}}>
          <b>Current</b>
        </Col>
        </Row>
        <Row>
        <Col md="6">
          <div style={{fontSize: '1.3rem', lineHeight:'1.3rem'}}>{totalOrderCount}</div>
          <div style={{fontSize: '.6rem'}}>Orders</div>
        </Col>
        <Col md="6">
          <div style={{fontSize: '1.3rem', lineHeight:'1.3rem'}}>{totalOrderLineItems}</div>
          <div style={{fontSize: '.6rem'}}>Items</div>
        </Col>
        </Row>
      </div>
      <UncontrolledButtonDropdown>
        <DropdownToggle color="Dark" className="p-0 d-flex align-items-center no-caret">
          <div className="header-count">
            <Row>
            <Col md="12" style={{fontSize:'.9rem'}}>
              <b>Future</b>
            </Col>
            </Row>
            <Row>
            <Col md="6">
              <div style={{fontSize: '1.3rem', lineHeight:'1.3rem'}}>{totalFutureOrderCount}</div>
              <div style={{fontSize: '.6rem'}}>Orders</div>
            </Col>
            <Col md="6">
              <div style={{fontSize: '1.3rem', lineHeight:'1.3rem'}}>{totaFuturelOrderLineItems}</div>
              <div style={{fontSize: '.6rem'}}>Items</div>
            </Col>
            </Row>
          </div>
        </DropdownToggle>
        {futureOrders.length > 0 &&
        <DropdownMenu>
            <PerfectScrollbar>
              <Nav vertical>
                <NavItem>
                  {futureOrders.map((order) => (
                    <OrderSummary key={order.id} order={order} onClick={() => { 
                      setSelectedOrder(order);
                      setPickupTime(new Date(order.pickuptime));
                      togglePickupTimeModal();
                    }}/>
                  ))}
                </NavItem>
              </Nav>
            </PerfectScrollbar>
        </DropdownMenu>
        }
      </UncontrolledButtonDropdown>
      <Modal id="pick-up-time-modal" isOpen={showPickupTimeModal}>
        <ModalHeader>
          Do you want to change the pickup time for this order?
        </ModalHeader>
        <ModalBody>
          <InputGroup>
            <DatePicker selected={new Date(pickupTime)} onChange={handlePickupTimeChange} showTimeSelect timeIntervals={5} className="form-control"
              timeFormat="h:mm a" dateFormat="M/dd/yyyy hh:mm a" timeCaption="Time"/>
            <div className="input-group-text">
              <FontAwesomeIcon icon={faCalendarAlt} />
            </div>
          </InputGroup>
        </ModalBody>
        <ModalFooter>
          <button id="Now" onClick={startItNow} className="btn btn-primary">Now</button>
          <button id="Cancel" onClick={togglePickupTimeModal} className="btn btn-secondary">Cancel</button>
          <button id="Ok" onClick={() => {saveNewPickupTime(pickupTime)}} className="btn btn-primary">Save</button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default OrderCount;
